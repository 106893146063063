import { sendPasswordResetCode } from 'src/Reset/businessLogic/sendPasswordResetCode'

interface Props {
  brandingKey: string
  email: string
  versionCode: string
  onSuccess: () => void
}
export const startPasswordReset = async ({
  brandingKey,
  email,
  versionCode,
  onSuccess,
}: Props) => {
  const success = await sendPasswordResetCode(versionCode, email, brandingKey)
  if (success) {
    onSuccess()
  }
}
