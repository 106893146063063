import { ApolloClient, ApolloLink, createHttpLink } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { SERVER_GATEWAY } from 'src/config'
import { authLink, errorLink, localeLink } from './links'

const makeLink = (versionCode: string) => {
  const httpLink = createHttpLink({ uri: SERVER_GATEWAY })
  const links = [
    authLink(versionCode),
    localeLink,
    errorLink,
    genError,
    httpLink,
  ]
  return ApolloLink.from(links)
}

export const genError = onError(
  ({ response, graphQLErrors, networkError, operation, forward }) => {
    if (operation.getContext().handlesErrors) {
      return forward(operation)
    }

    if (graphQLErrors || networkError) {
      if (response && response.errors) {
        console.log(`Error making Apollo request`, response.errors)
        response.errors = undefined
      }
      return forward(operation)
    }
  }
)

const cache = new InMemoryCache()
const client = (versionCode: string) => {
  const link = makeLink(versionCode)
  return new ApolloClient({ link, cache })
}

export default client
