import { useHistory } from 'react-router-dom'
import MakeFormScreen from 'src/common/components/GenericForm'
import { InputList } from 'src/common/components/GenericForm/types'
import BottomText from 'src/common/components/TextInputs/BottomText'
import { localize } from 'src/lib/localize'
import { useWhiteLabel } from 'src/white-label'
import { resetPasswordAction } from './businessLogic/resetPasswordAction'

interface UserInfo {
  email: string
  code?: string
}

const SetPassword = () => {
  const history = useHistory()
  const { versionCode, texts } = useWhiteLabel()
  const userInfo = history.location.state as UserInfo | undefined

  return MakeFormScreen({
    headerTitle: localize('TITLE')('Password Reset'),
    title: localize('TITLE')('Set a new password'),
    prompt: localize('LABEL')('What do you want your new password to be?'),
    inputs,
    primaryButtonTitle: localize('TITLE')('Save Password'),
    preFillEffect: setHiddenEmail => {
      if (userInfo?.email) {
        setHiddenEmail(userInfo.email)
      }
    },
    onContinue: async (_, password, verification) => {
      const { success, errorMessage } = await resetPasswordAction({
        versionCode,
        email: userInfo?.email,
        code: userInfo?.code,
        password,
        passwordVerification: verification,
      })

      if (success) {
        const { appName } = texts
        const alertString = localize('LABEL')(
          'You have successfully changed your {{appName}} password.',
          { appName }
        )
        window.alert(alertString)
        history.go(-3) // Takes back to SignInPage
      } else {
        return errorMessage
      }
    },
  })
}

const inputs: InputList = [
  'HiddenInput',
  {
    type: 'PasswordInput',
    label: localize('LABEL')('New Password'),
  },
  {
    type: 'PasswordInput',
    label: localize('LABEL')('Confirm New Password'),
    hint: localize('LABEL')('Make sure they match.'),
    hintComponent: BottomText,
  },
]

export default SetPassword
