import { useHistory } from 'react-router-dom'
import MakeFormScreen from 'src/common/components/GenericForm'
import { localize } from 'src/lib/localize'
import { startPasswordReset } from './businessLogic/startPasswordReset'
import { useWhiteLabel } from 'src/white-label'

const Reset = () => {
  const history = useHistory()
  const { brandingKey, versionCode } = useWhiteLabel()

  return MakeFormScreen({
    headerTitle: localize('TITLE')('Password Reset'),
    title: localize('TITLE')('Forgot your Password?'),
    prompt: localize('LABEL')(
      'We’ll email you a verification code to the email address you registered with to reset your password. The code is only valid for 5 minutes.'
    ),
    inputs: [
      {
        type: 'EmailInput',
        label: localize('LABEL')('Email'),
      },
    ],
    primaryButtonTitle: localize('TITLE')('Send'),
    onContinue: async email => {
      await startPasswordReset({
        versionCode,
        email,
        brandingKey,
        onSuccess: () => {
          history.push('/verify-code', { email })
        },
      })
    },
  })
}

export default Reset
