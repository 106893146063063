import React from 'react'
import { useHistory } from 'react-router-dom'
import Container from 'src/common/components/Container'

export default () => {
  const { location } = useHistory()
  const query = new URLSearchParams(location.search)
  const code = query.get('code')
  return (
    <Container title="temp-redirect">
      <>
        <h1>Redirected Screen</h1>
        {code && <h4>Code received in query parameter: {code}</h4>}
      </>
    </Container>
  )
}
