import { useContext, useEffect } from 'react'
import { FormContext } from './Context'
import { SetFieldFn } from './types'

type OnUnmount = () => void
type PreFillProps = {
  onPreFill: (setField: SetFieldFn) => OnUnmount | undefined | void
}

export const PreFill = (props: PreFillProps) => {
  const { setField } = useContext(FormContext)
  useEffect(() => {
    return props.onPreFill(setField)
  }, [setField, props.onPreFill, props])

  return null
}
