import React, { useEffect, useState } from 'react'
import { Props, MetaProps } from './types'
import ThemedTextInput from 'src/common/components/ThemedTextInput'
import BottomText from './BottomText'
import styled from 'styled-components'

const Input = styled(ThemedTextInput)`
  margin-bottom: 0px;
`

export const makeInput =
  ({
    textInputProps,
    defaultLabel,
    defaultValidate,
    defaultFormat,
    defaultParse,
    defaultErrorMessage,
    defaultHintComponent = BottomText,
  }: MetaProps) =>
  ({
    label = defaultLabel,
    onChangeValue,
    onChangeValidationState,
    validate = defaultValidate,
    format = defaultFormat || identity,
    parse = defaultParse || identity,
    testID,
    value,
    showError = false,
    errorMessage = defaultErrorMessage,
    hintMessage,
    hintComponent = defaultHintComponent,
    fields,
    onFocus,
    onBlur,
    iOSTextContentType,
  }: Props) => {
    const formattedValue = value ? format(value) : ''

    const onChangeText = (e: any) => {
      const newText = e.target.value
      const parsedValue = parse(newText)
      onChangeValue(parsedValue)
      if (value === undefined && onChangeValidationState) {
        const validationState: boolean = validate(parsedValue)
        onChangeValidationState(validationState)
      }
    }

    useEffect(() => {
      if (value !== undefined && onChangeValidationState) {
        const validationState: boolean = validate(value, fields)
        onChangeValidationState(validationState)
      }
      // including `fields` in the list of dependencies causes endless rendering
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, validate, onChangeValidationState])

    const error = showError ? errorMessage : undefined

    const [focused, setFocused] = useState(false)

    // Typescript complains about this not having a standard call/construct signature
    // but it works below anyway
    const HintComponent = hintComponent as any

    return (
      <Input
        {...textInputProps}
        testID={testID}
        value={formattedValue}
        onChange={onChangeText}
        onFocus={(e: any) => {
          setFocused(true)
          if (onFocus) {
            onFocus(e)
          }
        }}
        onBlur={(e: any) => {
          setFocused(false)
          if (onBlur) {
            onBlur(e)
          }
        }}
        label={label}
        hasError={showError}
        textContentType={iOSTextContentType}
      >
        <HintComponent
          errorMessage={error}
          hintMessage={hintMessage}
          value={value}
          fields={fields}
          focused={focused}
        />
      </Input>
    )
  }

const identity = (x: any) => x
