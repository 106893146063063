import React, { useCallback, useContext, useEffect } from 'react'

import {
  Props as InputProps,
  HintComponent,
} from 'src/common/components/TextInputs/types'

import { FormField } from './types'
import { FormContext } from './Context'

type FormElementProps = {
  name: string
  label?: string
  hint?: string
  hintComponent?: HintComponent
  validation?: (value: string) => boolean
}

const defaultFieldState: FormField = {
  value: '',
  valid: false,
  showError: false,
}

export const wrapInput =
  (Input: React.ComponentType<InputProps>) => (props: FormElementProps) => {
    const { setField, onFieldMount, onFieldUnmount, fields } =
      useContext(FormContext)

    useEffect(() => {
      onFieldMount()
      return () => onFieldUnmount(props.name)
    }, [onFieldMount, onFieldUnmount, props.name])

    const field = {
      ...defaultFieldState,
      ...fields[props.name],
    }

    const onChangeValue = useCallback(
      (value: string) => {
        setField(props.name, {
          value,
          showError: false,
        })
      },
      [setField, props.name]
    )
    const onChangeValidationState = useCallback(
      (valid: boolean) => {
        setField(props.name, { valid })
      },
      [setField, props.name]
    )

    return (
      <Input
        key={props.name}
        value={field.value}
        onChangeValue={onChangeValue}
        onChangeValidationState={onChangeValidationState}
        label={props.label}
        showError={field.showError}
        hintMessage={props.hint}
        hintComponent={props.hintComponent}
        validate={props.validation}
        fields={fields}
      />
    )
  }
