import { makeInput } from './GenericInput'
import { localize } from 'src/lib/localize'

const defaultValidation = (pin: string): boolean => {
  return pin.length === 6
}

const PinInput = makeInput({
  textInputProps: {
    keyboardType: 'number-pad',
    placeholder: localize('LABEL')('Enter code'),
  },
  defaultLabel: 'PIN',
  defaultValidate: defaultValidation,
  defaultErrorMessage: 'Needs to be 6 characters.',
})

export default PinInput
