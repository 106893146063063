import { useHistory } from 'react-router-dom'
import { getWhiteLabel, defaultWhiteLabel } from './index'

const APP_BRANDING_KEY = 'app_branding'

export const useFindWhiteLable = () => {
  const { location } = useHistory()
  const query = new URLSearchParams(location.search)
  const app_branding = query.get(APP_BRANDING_KEY)
  if (app_branding) {
    return getWhiteLabel({ app_branding })
  } else return defaultWhiteLabel
}

export const getWhiteLable = (location: any) => {
  const query = new URLSearchParams(location.search)
  const app_branding = query.get(APP_BRANDING_KEY)
  if (app_branding) {
    return getWhiteLabel({ app_branding })
  } else {
    return defaultWhiteLabel
  }
}
