import styled from 'styled-components'
import { theme } from 'src/common/theme'

export const LargeTitle = styled.div`
  ${theme.textStyles.largeTitle};
  color: ${theme.colors.defaultTextColor};
`

export const Title = styled.div`
  ${theme.textStyles.title};
  color: ${theme.colors.defaultTextColor};
`

export const Title2 = styled.div`
  ${theme.textStyles.title2};
  color: ${theme.colors.defaultTextColor};
`

export const Title3 = styled.div`
  ${theme.textStyles.title3};
  color: ${theme.colors.defaultTextColor};
`

export const Title3Emphasized = styled.div`
  ${theme.textStyles.title3Emphasized};
  color: ${theme.colors.defaultTextColor};
`

export const Headline = styled.div`
  ${theme.textStyles.headline};
  color: ${theme.colors.defaultTextColor};
`

export const Subhead = styled.div`
  ${theme.textStyles.subhead};
  color: ${theme.colors.defaultTextColor};
`

export const SubheadEmphasized = styled.div`
  ${theme.textStyles.subheadEmphasized};
  color: ${theme.colors.defaultTextColor};
`

export const Body = styled.div`
  ${theme.textStyles.body};
  color: ${theme.colors.defaultTextColor};
`

export const Body1 = styled.div`
  ${theme.textStyles.body1};
  color: ${theme.colors.defaultTextColor};
`

export const Caption1 = styled.div`
  ${theme.textStyles.caption1};
  color: ${theme.colors.defaultTextColor};
`

export const Callout = styled.div`
  ${theme.textStyles.callout};
  color: ${theme.colors.defaultTextColor};
`

export const Caption2 = styled.div`
  color: ${theme.colors.highlightTextColor};
`

export const Overline = styled.div`
  ${theme.textStyles.overline};
`
