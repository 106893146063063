import { checkCode as checkCodeGateway } from 'src/Reset/businessLogic/checkCode'

interface Props {
  email?: string
  code?: string
  versionCode: string
  onSuccess: () => void
}
export const checkCode = async ({
  email,
  code,
  versionCode,
  onSuccess,
}: Props) => {
  if (!(email && code)) {
    return
  }

  const { success, errorMessage } = await checkCodeGateway(
    versionCode,
    email,
    code
  )
  if (success) {
    onSuccess()
  } else {
    return errorMessage
  }
}
