import React from 'react'
import { ReactComponent as Visible } from './visibility.svg'
import { ReactComponent as NotVisible } from './visibility_off.svg'
import { useTheme } from 'src/common/theme'
import styled from 'styled-components'

export type Props = {
  isVisible: boolean
  onPress?: () => void
}

const Button = styled.button`
  display: flex;
  outline: none;
  box-sizing: border-box;
  border: 0px solid;
  background-color: transparent;
  align-items: center;
`

export const VisibilityToggleButton = ({ isVisible, onPress }: Props) => {
  const { placeholderTextColor } = useTheme().colors
  // Show the opposite icon of the current visibility to indicate what happens
  // when pressed
  const Icon = isVisible ? NotVisible : Visible
  return (
    <Button onClick={onPress}>
      <Icon width={32} height={32} fill={placeholderTextColor} />
    </Button>
  )
}
