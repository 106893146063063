export default {
  "LABEL": {
    "\"Invalid email or password\"": "Correo electrónico o contraseña no válidos",
    "\"An error has occurred, please try again\"": "Ocurrió un error, inténtalo nuevamente",
    "\"Email\"": "Correo electrónico",
    "\"Password\"": "Contraseña",
    "\"Forgot My Password\"": "Olvidé mi contraseña",
    "\"We’ll email you a verification code to the email address you registered with to reset your password##PERIOD## The code is only valid for 5 minutes##PERIOD##\"": "Te enviaremos un código de verificación a la dirección de correo electrónico que registraste para restablecer tu contraseña##PERIOD## El código solo es válido por 5 minutos##PERIOD##",
    "\"Invalid code, please try again\"": "Código no válido, inténtalo nuevamente",
    "\"Code has expired\"": "El código ha expirado",
    "\"Error resetting password\"": "Error al restablecer la contraseña",
    "\"What do you want your new password to be?\"": "¿Cuál deseas que sea tu nueva contraseña?",
    "\"New Password\"": "Nueva contraseña",
    "\"Confirm New Password\"": "Confirmar nueva contraseña",
    "\"Make sure they match##PERIOD##\"": "Asegúrate de que coincidan##PERIOD##",
    "\"Password does not match confirmation field\"": "La contraseña no coincide con el campo de confirmación",
    "\"Code verification not done\"": "Verificación de código no realizada",
    "\"You have successfully changed your {{appName}} password##PERIOD##\"": "Has cambiado correctamente la contraseña de {{appName}}##PERIOD##",
    "\"Error verifying code\"": "Error al verificar el código",
    "\"Enter the verification code sent to {{email}}\"": "Ingresa el código de verificación enviado a {{email}}",
    "\"Enter the verification code sent to your email\"": "Ingresa el código de verificación enviado a correo electrónico",
    "\"Verification Code\"": "Código de verificación",
    "\"New code sent\"": "Nuevo código enviado",
    "\"Failed to send new code, please try again\"": "No se pudo enviar el nuevo código. Vuelve a intentarlo.",
    "\"Not a valid email address##PERIOD##\"": "No es una dirección de correo electrónico válida##PERIOD##",
    "\"At least 8 characters\"": "Al menos 8 caracteres",
    "\"At least 1 uppercase letter\"": "Al menos 1 letra mayúscula",
    "\"At least 1 lowercase letter\"": "Al menos 1 letra minúscula",
    "\"At least 1 numeric character\"": "Al menos 1 carácter numérico",
    "\"At least 1 special character (!@#$%^&*)\"": "Al menos 1 carácter especial (!@#$%^&*)",
    "\"No parts of your username\"": "No hay partes de su nombre de usuario",
    "\"Password must include the following:\"": "La contraseña debe incluir lo siguiente:",
    "\"Invalid password\"": "Contraseña inválida",
    "\"Enter code\"": "Ingresar código"
  },
  "TITLE": {
    "\"Please provide the following information to sign into your account##PERIOD## These credentials are same as the Secure Backup feature in {{appName}}##PERIOD##\"": "Proporciona la siguiente información para iniciar sesión en tu cuenta##PERIOD## Estas credenciales son las mismas que las de la función de copia de seguridad segura en {{appName}}##PERIOD##",
    "\"Sign In\"": "Iniciar sesión",
    "\"Welcome back!\"": "¡Bienvenido de nuevo!",
    "\"Password Reset\"": "Restablecimiento de contraseña",
    "\"Forgot your Password?\"": "¿Olvidaste tu contraseña?",
    "\"Send\"": "Enviar",
    "\"Set a new password\"": "Establecer una nueva contraseña",
    "\"Save Password\"": "Guardar contraseña",
    "\"Verification Code Sent\"": "Código de verificación enviado",
    "\"Next\"": "Aceptar",
    "\"Send New Code\"": "Enviar código nuevo"
  }
}