import { useHistory } from 'react-router-dom'
import MakeFormScreen from 'src/common/components/GenericForm'
import { localize } from 'src/lib/localize'
import { useEmailPrompt } from 'src/Reset/businessLogic/useEmailPrompt'
import { checkCode } from 'src/Reset/ui/VerificationCode/businessLogic/checkCode'
import { resendCode } from 'src/Reset/ui/VerificationCode/businessLogic/resendCode'
import { useWhiteLabel } from 'src/white-label'

interface UserInfo {
  email: string
  code?: string
}

const VerificationCode = () => {
  const history = useHistory()
  const { versionCode, brandingKey } = useWhiteLabel()
  const userInfo = history.location.state as UserInfo | undefined
  const email = userInfo?.email
  const prompt = useEmailPrompt(email)

  return MakeFormScreen({
    headerTitle: localize('TITLE')('Password Reset'),
    title: localize('TITLE')('Verification Code Sent'),
    prompt,
    inputs: [
      { type: 'PinInput', label: localize('LABEL')('Verification Code') },
    ],
    primaryButtonTitle: localize('TITLE')('Next'),
    onContinue: async code =>
      await checkCode({
        email,
        code,
        versionCode,
        onSuccess: () => {
          history.push('/set-password', { ...userInfo, code })
        },
      }),
    secondaryButton: {
      title: localize('TITLE')('Send New Code'),
      onPress: async () => {
        return await resendCode({
          email,
          versionCode,
          brandingKey,
          onSuccess: () => {
            window.alert(localize('LABEL')('New code sent'))
          },
        })
      },
    },
  })
}

export default VerificationCode
