import React from 'react'

import { FormState } from './types'

export const FormContext = React.createContext<FormState>({
  setField: () => {},
  onFieldMount: () => {},
  onFieldUnmount: () => {},
  fields: {},
  numFields: 0,
})
