import { localize } from 'src/lib/localize'

/**
 * @param email The email to be displayed in the prompt, if available.
 * @returns A localized string stating the verification code has been sent.
 */
export const useEmailPrompt = (email: string | undefined | null) =>
  email
    ? localize('LABEL')('Enter the verification code sent to {{email}}', {
        email: email,
      })
    : localize('LABEL')('Enter the verification code sent to your email')
