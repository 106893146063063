import React from 'react'
import { useTheme } from 'src/common/theme'
import { HintProps } from '../types'
import BottomText from '../BottomText'
import { noEmailParts } from './noEmailParts'
import styled from 'styled-components'
import { FormFieldSet } from 'src/common/components/Form/types'
import { Caption1 } from 'src/common/text-styles'
import { localize } from 'src/lib/localize'

import { ReactComponent as PasswordMiniCheck } from './PasswordMiniCheck.svg'
import { ReactComponent as PasswordMiniNeutral } from './PasswordMiniNeutral.svg'
import { ReactComponent as PasswordMiniX } from './PasswordMiniX.svg'

const lengthValidation = (value: string) => value.length >= 8
const upperCaseValidation = (value: string) => /[A-Z]/.test(value)
const lowerCaseValidation = (value: string) => /[a-z]/.test(value)
const numValidation = (value: string) => /[0-9]/.test(value)
const specialValidation = (value: string) => /[!@#$%^&*?()]/.test(value)

// This is ugly but find the `email` field by testing for an `@` in it
const usernameValidation = (value: string, fields?: FormFieldSet) => {
  if (!fields) {
    return false
  }

  const allValues = Object.values(fields).map(f => f.value)
  const email = allValues.find(v => /@/.test(v))
  if (!email) {
    return false
  }

  return noEmailParts(email, value)
}

export const allValid = (value: string, fields?: FormFieldSet) =>
  lengthValidation(value) &&
  upperCaseValidation(value) &&
  lowerCaseValidation(value) &&
  numValidation(value) &&
  specialValidation(value) &&
  usernameValidation(value, fields)

interface SectionProps {
  valid: boolean | 'indeterminate'
  children: string
}

const SectionRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: 3px;
  margin-left: 14px;
  margin-top: 4px;
  margin-bottom: 2px;
`

const DescriptionText = styled(Caption1)`
  display: flex;
  margin-left: 8px;
  margin-right: 16px;
`

const HeaderDescriptionText = styled(DescriptionText)`
  display: flex;
  margin-top: 4px;
`

const Section = ({ valid, children }: SectionProps) => {
  const { black, inputError } = useTheme().colors
  let Icon
  switch (valid) {
    case 'indeterminate':
      Icon = PasswordMiniNeutral
      break
    case true:
      Icon = PasswordMiniCheck
      break
    case false:
      Icon = PasswordMiniX
      break
    default:
      Icon = PasswordMiniNeutral
      break
  }

  return (
    <SectionRow>
      <Icon />
      <DescriptionText style={{ color: valid ? black : inputError }}>
        {children}
      </DescriptionText>
    </SectionRow>
  )
}

const lengthLabel = localize('LABEL')('At least 8 characters')
const caseLabel = localize('LABEL')('At least 1 uppercase letter')
const lowCaseLabel = localize('LABEL')('At least 1 lowercase letter')
const numLabel = localize('LABEL')('At least 1 numeric character')
const specialLabel = localize('LABEL')(
  'At least 1 special character (!@#$%^&*)'
)
const usernameLabel = localize('LABEL')('No parts of your username')
const passwordHintLabel = localize('LABEL')(
  'Password must include the following:'
)

const PasswordHintText = (props: HintProps) => {
  if (!props.focused) {
    return <BottomText {...props} />
  }
  const { value, fields } = props
  const lengthValid = lengthValidation(value)
  const isIndeterminate = lengthValid ? false : 'indeterminate'
  const upCaseValid = upperCaseValidation(value) || isIndeterminate
  const lowCaseValid = lowerCaseValidation(value) || isIndeterminate
  const numValid = numValidation(value) || isIndeterminate
  const specialValid = specialValidation(value) || isIndeterminate
  const usernameValid = usernameValidation(value, fields) || isIndeterminate
  return (
    <>
      <HeaderDescriptionText>{passwordHintLabel}</HeaderDescriptionText>
      <Section valid={lengthValid}>{lengthLabel}</Section>
      <Section valid={upCaseValid}>{caseLabel}</Section>
      <Section valid={lowCaseValid}>{lowCaseLabel}</Section>
      <Section valid={numValid}>{numLabel}</Section>
      <Section valid={specialValid}>{specialLabel}</Section>
      <Section valid={usernameValid}>{usernameLabel}</Section>
    </>
  )
}

export default PasswordHintText
