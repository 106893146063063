import React, { useState, useMemo } from 'react'
import Container from 'src/common/components/Container'

import makeForm from './Content'
import { InputList, ExtraButton, PreFillEffect } from './types'
import LoadingOverlay from 'src/common/components/LoadingOverlay'

// We could just catch errors and parse the message out, but many of those are
// not intended to be user-visible. So this return type makes it obviouos when
// a message is intended to be shown on the UI
export type DoWorkFn = (...values: string[]) => Promise<string | void>

export type SecondayButton = {
  title: string
  onPress: (...values: string[]) => Promise<string | void>
}

interface MetaProps {
  topImageSVGElement?: JSX.Element
  disclaimer?: JSX.Element
  headerTitle: string
  title: string
  prompt: string
  inputs: InputList
  primaryButtonTitle?: string
  onContinue: DoWorkFn
  secondaryButton?: SecondayButton
  extraButtons?: ExtraButton[]
  preFillEffect?: PreFillEffect
  consentText?: JSX.Element
}

const GenericForm = ({
  topImageSVGElement,
  disclaimer,
  headerTitle,
  title,
  prompt,
  inputs,
  primaryButtonTitle,
  onContinue,
  secondaryButton,
  extraButtons,
  preFillEffect,
  consentText,
}: MetaProps) => {
  const Content = useMemo(
    () =>
      makeForm({
        topImageSVGElement,
        disclaimer,
        title,
        prompt,
        inputs,
        primaryButtonTitle,
        secondaryButton,
        extraButtons,
        preFillEffect,
        consentText,
      }),
    [
      topImageSVGElement,
      disclaimer,
      title,
      prompt,
      inputs,
      primaryButtonTitle,
      secondaryButton,
      extraButtons,
      preFillEffect,
      consentText,
    ]
  )

  const [working, setWorking] = useState(false)
  const [secondaryWorking, setSecondaryWorking] = useState(false)

  // Because of the above thing this has to be separate
  const [overlayVisible, setOverlayVisible] = useState(false)

  const [error, setError] = useState<string | undefined>(undefined)
  const startWorking = useMemo(() => {
    return (...values: string[]) => {
      // Keyboard.dismiss()
      setWorking(true)
      setOverlayVisible(true)
      setError(undefined)
      onContinue(...values)
        .then(result => {
          if (result !== undefined) {
            setWorking(false)
            setError(result)
          }
          // Otherwise assume navigation will happen and dont re-enable the button
          setOverlayVisible(false)
        })
        .catch(err => {
          console.log(err)
          setWorking(false)
          setOverlayVisible(false)
          setError(
            'An error occurred, please check your connection and try again'
          )
        })
    }
  }, [onContinue, setWorking, setError])
  let startWorkingSecondary = startWorking

  startWorkingSecondary = useMemo(() => {
    return (...values: string[]) => {
      // Keyboard.dismiss()
      setSecondaryWorking(true)
      setOverlayVisible(true)
      setError(undefined)
      secondaryButton
        ?.onPress(...values)
        .then(result => {
          setSecondaryWorking(false)
          if (result !== undefined) {
            setError(result)
          }
          // Otherwise assume navigation will happen and dont re-enable the button
          setOverlayVisible(false)
        })
        .catch(err => {
          console.log(err)
          setSecondaryWorking(false)
          setOverlayVisible(false)
          setError(
            'An error occurred, please check your connection and try again'
          )
        })
    }
  }, [secondaryButton])

  return (
    <Container title={headerTitle}>
      <>
        <Content
          onContinue={startWorking}
          onSecondary={startWorkingSecondary}
          working={working}
          secondaryWorking={secondaryWorking}
          errorMessage={error}
        />
        <LoadingOverlay visible={overlayVisible} />
      </>
    </Container>
  )
}

export default GenericForm
