import { useOktaAuth } from '@okta/okta-react'
import { OktaAuth } from '@okta/okta-auth-js'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import MakeFormScreen, { DoWorkFn } from 'src/common/components/GenericForm'
import { PreFillEffect } from 'src/common/components/GenericForm/types'
import BottomText from 'src/common/components/TextInputs/BottomText'
import { localize } from 'src/lib/localize'
import { useWhiteLabel } from 'src/white-label'

const prePopulateFields: PreFillEffect = (setEmail, setPassword) => {
  const search = window.location.search
  const query = new URLSearchParams(search)
  const email = query.get('email')
  if (email) {
    setEmail(email)
  }
  const password = query.get('password')
  if (password) {
    setPassword(password)
  }
}

export const Signin = () => {
  const { oktaAuth }: { oktaAuth: OktaAuth } = useOktaAuth()
  const [sessionToken, setSessionToken] = useState<string>()
  const { texts } = useWhiteLabel()
  const history = useHistory()
  const onContinue = (): DoWorkFn => async (email, password) => {
    const username = `PG__${email}`
    const invalidCredentials = localize('LABEL')('Invalid email or password')
    const errorMessage = localize('LABEL')(
      'An error has occurred, please try again'
    )
    const res = await oktaAuth
      .signInWithCredentials({ username, password })
      .catch((err: any) => {
        console.log('oktaAuth Error: ', err)
        //Handled only authendication error for complete error list please visit: https://developer.okta.com/docs/reference/error-codes/
        if (err.errorCode === 'E0000004') {
          return invalidCredentials
        } else {
          return errorMessage
        }
      })
    if (typeof res === 'string') {
      return res
    }

    const { sessionToken } = res
    setSessionToken(sessionToken)
    oktaAuth.signInWithRedirect({ sessionToken })
  }

  if (sessionToken) {
    // Hide form while sessionToken is converted into id/access tokens
    return null
  }

  const prompt = localize('TITLE')(
    'Please provide the following information to sign into your account. These credentials are same as the Secure Backup feature in {{appName}}.',
    {
      appName: texts.appName,
    }
  )
  const headerTitle = localize('TITLE')('Sign In')
  const emailLabel = localize('LABEL')('Email')
  const passwordLabel = localize('LABEL')('Password')
  const forgotPasswordTitle = localize('LABEL')('Forgot My Password')
  const welcomebackTitle = localize('TITLE')('Welcome back!')
  return (
    <MakeFormScreen
      headerTitle={headerTitle}
      title={welcomebackTitle}
      prompt={prompt}
      inputs={[
        {
          type: 'EmailInput',
          label: emailLabel,
        },
        {
          type: 'PasswordInput',
          label: passwordLabel,
          hintComponent: BottomText,
          validation: (value: string) => value.length > 1,
        },
      ]}
      primaryButtonTitle={headerTitle}
      onContinue={onContinue()}
      extraButtons={[
        {
          title: forgotPasswordTitle,
          onPress: async () => {
            history.push('/reset')
          },
        },
      ]}
      preFillEffect={prePopulateFields}
    />
  )
}

export default Signin
