import React from 'react'
import { useTheme } from 'src/common/theme'
import { HintProps } from './types'
import { Caption1 } from 'src/common/text-styles'
import styled from 'styled-components'

const ErrorText = styled(Caption1)`
  margin-top: 4px;
`
const BottomText = ({ errorMessage, hintMessage }: HintProps) => {
  const hasError = Boolean(errorMessage)
  const hasMessage = Boolean(errorMessage || hintMessage)
  const { black, inputError } = useTheme().colors
  return hasMessage ? (
    <ErrorText style={{ color: hasError ? inputError : black }}>
      {hasError ? errorMessage : hintMessage}
    </ErrorText>
  ) : null
}

export default BottomText
