import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Headline } from 'src/common/text-styles'
import { theme, Theme } from 'src/common/theme'
import { VisibilityToggleButton } from './VisibilityToggleButton'

const getColor = (props: any) =>
  props.hasError ? theme.colors.inputError(props) : theme.colors.black(props)

const getPaddingRight = (props: any) => (props.hasVisibilityToggle ? 40 : 0)

const Title = styled(Headline)<{ hasError: boolean }>`
  color: ${getColor};
`

const IosStyledTextInput = styled.input<{ hasError: boolean }>`
  display: flex;
  box-sizing: border-box;
  ${theme.textStyles.body};
  min-height: 42px;
  border: 1px solid ${getColor};
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: ${getPaddingRight}px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  outline: none;
`

const InputContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`
const TextContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  ${theme.textStyles.body};
  width: 100%;
  flex-direction: column;
  justfy-content: center;
  align-items: flex-end;
  outline: none;
  background-color: transparent;
`

const EyeContainer = styled.div`
  display: flex;
  position: absolute;
  outline: none;
  min-height: 42px;
  margin-top: 10px;
  border-radius: 10px;
`

export const IosThemedTextInput = (props: any) => {
  const { label, style } = props
  const { colors } = useContext(Theme)
  const [textVisible, setTextVisible] = useState(false)

  const innerProps = {
    ...props,
    label: undefined,
    style: undefined,
    children: undefined,
    hasError: props.hasError || false,
  }

  return (
    <InputContainer style={style}>
      <Title hasError={props.hasError || false}>{label}</Title>
      <TextContainer>
        <IosStyledTextInput
          {...innerProps}
          type={props.hasVisibilityToggle && textVisible ? 'text' : props.type}
          placeholderTextColor={colors.disabledGrey}
        />
        {props.hasVisibilityToggle && (
          <EyeContainer>
            <VisibilityToggleButton
              isVisible={textVisible}
              onPress={() => setTextVisible(!textVisible)}
            />
          </EyeContainer>
        )}
      </TextContainer>
      {props.children}
    </InputContainer>
  )
}

export default IosThemedTextInput
