import React, { CSSProperties, useCallback, useContext } from 'react'
import { DarkButton } from 'src/common/components/ThemedButton'
import { FormContext } from './Context'
import { FormFieldSet } from './types'

export type SubmitButtonProps = {
  testID?: string
  style?: CSSProperties
  title: string
  onPress: (fields: FormFieldSet) => void
  disabled?: boolean
  secondary?: boolean
  onPressRef?: React.MutableRefObject<() => void>
}

export const ExtraButton = ({
  style,
  title,
  onPress,
  disabled,
  secondary,
  onPressRef,
}: SubmitButtonProps) => {
  const { fields } = useContext(FormContext)
  const _onPress = useCallback(() => onPress(fields), [onPress, fields])
  if (onPressRef) {
    onPressRef.current = _onPress
  }
  const onPressed = onPressRef ? onPressRef.current : _onPress

  return (
    <DarkButton
      style={style}
      testID="inner-button"
      title={title}
      onPress={onPressed}
      disabled={disabled}
      secondary={secondary}
    />
  )
}

export const SubmitButton = (props: SubmitButtonProps) => {
  const { setField, numFields } = useContext(FormContext)
  const _onPress = useCallback(
    (fields: FormFieldSet) => {
      // Check if any fields are invalid before continuing
      let valid = true
      let fieldCount = 0
      for (const key in fields) {
        const field = fields[key]
        if (!field.valid) {
          valid = false
          setField(key, { ...field, showError: true })
        }
        fieldCount++
      }

      // Check to make sure we actually have all the fields
      const haveAllFieldInfo = fieldCount >= numFields

      if (valid && haveAllFieldInfo) {
        props.onPress(fields)
      }
    },
    [numFields, setField, props]
  )

  return <ExtraButton {...props} onPress={_onPress} />
}
