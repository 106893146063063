import React from 'react'
import { useHistory, BrowserRouter } from 'react-router-dom'
import SecureRouter from './SecureRouter'

import { WhiteLabelContext } from 'src/white-label'
import { getWhiteLable } from 'src/white-label/FindWhiteLabel'
import { AppThemeProvider } from 'src/common/theme'

const WhiteLabelRouter = () => {
  const { location } = useHistory()
  const whiteLabel = getWhiteLable(location)
  return (
    <WhiteLabelContext.Provider value={whiteLabel}>
      <AppThemeProvider theme={whiteLabel.theme}>
        <SecureRouter />
      </AppThemeProvider>
    </WhiteLabelContext.Provider>
  )
}

export default () => {
  return (
    <BrowserRouter>
      <WhiteLabelRouter />
    </BrowserRouter>
  )
}
