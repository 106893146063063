import React, { useCallback, useReducer } from 'react'
import { FormContext } from './Context'
import { FormField, FormFieldSet } from './types'

type Props = {
  children: React.ReactNode
}

export const Form = ({ children }: Props) => {
  const [numFields, dispatchNum] = useReducer(fieldCountReducer, 0)
  const [fields, dispatch] = useReducer(fieldReducer, {})
  const setField = useCallback(
    (key: string, value: Partial<FormField>) =>
      dispatch({ type: 'set', key, value }),
    [dispatch]
  )
  const onFieldMount = useCallback(
    () => dispatchNum('increment'),
    [dispatchNum]
  )
  const onFieldUnmount = useCallback(
    (key: string) => {
      dispatch({ type: 'remove', key })
      dispatchNum('decrement')
    },
    [dispatch, dispatchNum]
  )

  // I don't think useMemo will be super helpful here since fields will always
  // be changing.
  const formState = {
    setField,
    onFieldMount,
    onFieldUnmount,
    fields,
    numFields,
  }

  return (
    <FormContext.Provider value={formState}>{children}</FormContext.Provider>
  )
}

type Action =
  | {
      type: 'set'
      key: string
      value: Partial<FormField>
    }
  | { type: 'remove'; key: string }

const fieldReducer = (fields: FormFieldSet, action: Action): FormFieldSet => {
  switch (action.type) {
    case 'set':
      return {
        ...fields,
        [action.key]: {
          ...fields[action.key],
          ...action.value,
        },
      }
    case 'remove': {
      const { [action.key]: _field, ...rest } = fields
      return rest
    }
  }
}

const fieldCountReducer = (
  numFields: number,
  action: 'increment' | 'decrement'
) => {
  switch (action) {
    case 'increment':
      return numFields + 1
    case 'decrement':
      return numFields - 1
  }
}
