import * as Localization from 'expo-localization'
import i18n from 'i18n-js'
import en from '../localizations/en'
import es from '../localizations/es'
import { TranslateOptions } from 'i18n-js'

type LocalizationCategory = 'TITLE' | 'BUTTON' | 'LABEL'

type StringContents = string | PluralizationContent

type PluralizationContent = {
  other: string
  zero: string
  one: string
}

i18n.translations = {
  en,
  'en-US': en,
  es,
  'es-ES': es,
}
i18n.locale = Localization.locale
i18n.fallbacks = true

export const localize =
  (category: LocalizationCategory) =>
  (string: StringContents, args2?: TranslateOptions) => {
    const identifier = JSON.stringify(string).replace(/\./g, '##PERIOD##')

    return i18n
      .t(`${category}.${identifier}`, args2)
      .replace(/##PERIOD##/g, '.')
  }
