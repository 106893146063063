import gql from 'graphql-tag'

export const START_PASSWORD_RESET = gql`
  mutation startPasswordReset($email: String!, $appBrandingKey: String!) {
    startPasswordReset(
      input: { email: $email, appBrandingKey: $appBrandingKey }
    ) {
      success
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $email: String!
    $code: String!
    $newPassword: String!
  ) {
    resetPassword(
      input: { email: $email, code: $code, newPassword: $newPassword }
    ) {
      success
    }
  }
`

export const CHECK_CODE = gql`
  mutation checkCode($email: String!, $code: String!) {
    checkPasswordResetCode(input: { email: $email, code: $code }) {
      success
    }
  }
`
