export default {
  "LABEL": {
    "\"Invalid email or password\"": "Invalid email or password",
    "\"An error has occurred, please try again\"": "An error has occurred, please try again",
    "\"Email\"": "Email",
    "\"Password\"": "Password",
    "\"Forgot My Password\"": "Forgot My Password",
    "\"We’ll email you a verification code to the email address you registered with to reset your password##PERIOD## The code is only valid for 5 minutes##PERIOD##\"": "We’ll email you a verification code to the email address you registered with to reset your password##PERIOD## The code is only valid for 5 minutes##PERIOD##",
    "\"Invalid code, please try again\"": "Invalid code, please try again",
    "\"Code has expired\"": "Code has expired",
    "\"Email is empty\"": "Email is empty",
    "\"Error resetting password\"": "Error resetting password",
    "\"What do you want your new password to be?\"": "What do you want your new password to be?",
    "\"New Password\"": "New Password",
    "\"Confirm New Password\"": "Confirm New Password",
    "\"Make sure they match##PERIOD##\"": "Make sure they match##PERIOD##",
    "\"Password does not match confirmation field\"": "Password does not match confirmation field",
    "\"Code verification not done\"": "Code verification not done",
    "\"You have successfully changed your {{appName}} password##PERIOD##\"": "You have successfully changed your {{appName}} password##PERIOD##",
    "\"Error verifying code\"": "Error verifying code",
    "\"Enter the verification code sent to {{email}}\"": "Enter the verification code sent to {{email}}",
    "\"Enter the verification code sent to your email\"": "Enter the verification code sent to your email",
    "\"Verification Code\"": "Verification Code",
    "\"New code sent\"": "New code sent",
    "\"Failed to send new code, please try again\"": "Failed to send new code, please try again",
    "\"Not a valid email address##PERIOD##\"": "Not a valid email address##PERIOD##",
    "\"At least 8 characters\"": "At least 8 characters",
    "\"At least 1 uppercase letter\"": "At least 1 uppercase letter",
    "\"At least 1 lowercase letter\"": "At least 1 lowercase letter",
    "\"At least 1 numeric character\"": "At least 1 numeric character",
    "\"At least 1 special character (!@#$%^&*)\"": "At least 1 special character (!@#$%^&*)",
    "\"No parts of your username\"": "No parts of your username",
    "\"Password must include the following:\"": "Password must include the following:",
    "\"Invalid password\"": "Invalid password",
    "\"Enter code\"": "Enter code"
  },
  "TITLE": {
    "\"Please provide the following information to sign into your account##PERIOD## These credentials are same as the Secure Backup feature in {{appName}}##PERIOD##\"": "Please provide the following information to sign into your account##PERIOD## These credentials are same as the Secure Backup feature in {{appName}}##PERIOD##",
    "\"Sign In\"": "Sign In",
    "\"Welcome back!\"": "Welcome back!",
    "\"Password Reset\"": "Password Reset",
    "\"Forgot your Password?\"": "Forgot your Password?",
    "\"Send\"": "Send",
    "\"Set a new password\"": "Set a new password",
    "\"Save Password\"": "Save Password",
    "\"Verification Code Sent\"": "Verification Code Sent",
    "\"Next\"": "Next",
    "\"Send New Code\"": "Send New Code"
  }
}