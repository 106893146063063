import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Security, LoginCallback } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import Signin from 'src/Auth/ui/Signin'
import Reset from 'src/Reset/ui/ResetPassword'
import VerificationCode from 'src/Reset/ui/VerificationCode'
import SetPassword from 'src/Reset/ui/SetPassword'

import TempRedirect from 'src/screen/temp-redirect'
import { useWhiteLabel } from 'src/white-label'
import { OKTA_ISSUER } from 'src/config'

const parseQuery = (location: any) => {
  const query = new URLSearchParams(location.search)
  const redirectUri = query.get('redirect_uri')
  return { redirectUri }
}

const AppWithRouterAccess = () => {
  const history = useHistory()
  const onAuthRequired = () => {
    history.push('/signin')
  }
  const { redirectUri } = parseQuery(history.location)
  const { clientId } = useWhiteLabel()
  console.log(`Redirect uri: ${redirectUri}`)

  const oktaAuth = new OktaAuth({
    issuer: OKTA_ISSUER,
    clientId,
    redirectUri: redirectUri ?? 'dummyredirect',
    pkce: false,
    responseType: ['code'],
    scopes: ['openid', 'profile'],
  })

  const SigninForm = () => {
    if (!redirectUri) {
      return <div>Bad Request</div>
    }
    return <Signin />
  }

  const ResetForm = () => {
    return <Reset />
  }

  const VerifciationCodeForm = () => {
    return <VerificationCode />
  }

  const SetPasswordForm = () => {
    return <SetPassword />
  }

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(
          toRelativeUrl(originalUri || '/', window.location.origin)
        )
      }}
    >
      <Route path="/signin" render={SigninForm} />
      <Route path="/reset" render={ResetForm} />
      <Route exact path="/" render={SigninForm} />
      <Route path="/implicit/callback" component={LoginCallback} />
      <Route path="/web/sso/oauth/callback" component={TempRedirect} />
      <Route path="/verify-code" render={VerifciationCodeForm} />
      <Route path="/set-password" render={SetPasswordForm} />
    </Security>
  )
}
export default AppWithRouterAccess
