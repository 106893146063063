import { createContext, useContext } from 'react'
import { DefaultTheme, DeviceSupportTheme } from 'src/common/theme'
import { CLIENT_ID, IDP } from 'src/config'
import { ReactComponent as DeviceSupportCloudLogo } from 'src/images/device-support-cloud-logo.svg'
import { ReactComponent as PGCloudLogo } from 'src/images/pg-cloud-logo.svg'
interface GetWhiteLabelProps {
  app_branding: string
}

export const getWhiteLabel = ({ app_branding }: GetWhiteLabelProps) => {
  let whiteLabel = WhiteLabel_PG
  if (app_branding) {
    whiteLabels.forEach(item => {
      if (app_branding === item.code) {
        whiteLabel = item
      }
    })
  }
  return whiteLabel
}

const DefaultWhiteLabel = {
  theme: DefaultTheme,
  code: 'pg_mobile',
  brandingKey: 'other',
  versionCode: '3',
  texts: {
    appName: 'Pocket Geek Mobile',
  },
  clientId: CLIENT_ID,
  idp: IDP,
  logo: PGCloudLogo,
}

type WhiteLabel = typeof DefaultWhiteLabel

const WhiteLabel_PG: WhiteLabel = DefaultWhiteLabel

const WhiteLabel_VODA: WhiteLabel = {
  ...DefaultWhiteLabel,
  theme: DeviceSupportTheme,
  code: 'device_support',
  brandingKey: 'vodafone',
  versionCode: '5',
  texts: {
    appName: 'Device Support',
  },
  logo: DeviceSupportCloudLogo,
}

const whiteLabels: WhiteLabel[] = [WhiteLabel_PG, WhiteLabel_VODA]

export const defaultWhiteLabel = WhiteLabel_PG

export const WhiteLabelContext = createContext<WhiteLabel>(defaultWhiteLabel)

export const useWhiteLabel = () => useContext(WhiteLabelContext)
