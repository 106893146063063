import { localize } from 'src/lib/localize'
import { resetPassword } from 'src/Reset/ui/SetPassword/businessLogic/resetPassword'

interface Result {
  success: boolean
  errorMessage?: string
}

interface Props {
  versionCode: string
  email?: string
  code?: string
  password: string
  passwordVerification: string
}
export const resetPasswordAction = async ({
  versionCode,
  email,
  code,
  password,
  passwordVerification,
}: Props): Promise<Result> => {
  if (password !== passwordVerification) {
    return {
      success: false,
      errorMessage: localize('LABEL')(
        'Password does not match confirmation field'
      ),
    }
  }

  if (!code) {
    return {
      success: false,
      errorMessage: localize('LABEL')('Code verification not done'),
    }
  }

  if (!email) {
    return {
      success: false,
      errorMessage: localize('LABEL')('Email is empty'),
    }
  }

  try {
    const success = await resetPassword(versionCode, email, code, password)

    if (success) {
      return { success: true }
    } else {
      return {
        success: false,
        errorMessage: localize('LABEL')('Invalid code, please try again'),
      }
    }
  } catch (err) {
    const defaultError = Error(localize('LABEL')('Error resetting password'))

    if (err instanceof Error) {
      switch (err.message) {
        case 'GraphQL error: Password reset code has expired':
          return {
            success: false,
            errorMessage: localize('LABEL')('Code has expired'),
          }
        default:
          throw defaultError
      }
    } else {
      throw defaultError
    }
  }
}
