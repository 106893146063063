import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

export const authLink = (versionCode: string) => {
  return setContext(async (_, { headers }) => {
    headers = {
      ...headers,
      'X-White-Label-Version-Code': versionCode,
    }
    return { headers }
  })
}

export const localeLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Accept-Language': 'en-US',
    },
  }
})

export const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.log(`!! GRAPHQL ERROR: ${JSON.stringify(graphQLErrors)}`)
  }
})
