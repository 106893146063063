import React, { useContext } from 'react'
import { ThemeProvider } from 'styled-components'
import { human, systemWeights, material } from 'react-typography'

const fontFamily = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif`

export const DefaultTheme = {
  colors: {
    barStyle: 'light-content',
    primary: '#0070B9',
    droop: '#0070B9',
    lightBackground: '#f9f9f9',
    svgAccentColor: '#ffffff',
    white: '#ffffff',
    disabledGrey: '#e7e7e7',
    disabledSubmitButton: '#1A54A8',
    destructiveAction: '#fe4a4a',
    defaultTextColor: '#000000',
    headerTextColor: '#ffffff',
    separator: '#dddddd',
    black: '#000000',
    highlightTextColor: '#222121',
    borderColor: '#dcdcdc;',
    loadingIndicator: '#111',
    inputError: '#fe4a4a',
    lightGray: '#adadad',
    darkGray: '#777777',
    green: '#4CD964',
    droopText: '#ffffff',
    headerTintColor: '#ffffff',
    headerTitleColor: '#ffffff',
    legalTextColor: '#ffffff',
    cardBanner: '#51b135',
    accountDisclaimer: '#e7eef4',
    flatListDivider: 'rgba(0, 0, 0, 0.3)',
    scanDialColor: '#0066CC',
    highlightUnderlay: 'rgba(255, 255, 255, 0.3)',
    lightOutline: '#d6d6d6',
    disabledLightOutline: '#cccccc',
    placeholderTextColor: '#b2b2b2',
  },
  textStyles: {
    largeTitle: {
      ...human.largeTitleObject,
      ...systemWeights.bold,
      fontFamily,
    },
    title: {
      ...human.title1Object,
      ...systemWeights.bold,
      fontFamily,
    },
    title2: {
      ...human.title2Object,
      ...systemWeights.bold,
      fontFamily,
    },
    title3: {
      ...human.title3Object,
      ...systemWeights.bold,
      fontFamily,
    },
    title3Emphasized: {
      ...human.title3Object,
      ...systemWeights.semibold,
      fontFamily,
    },
    headline: {
      ...human.headlineObject,
      ...systemWeights.semibold,
      fontFamily,
    },
    subhead: {
      ...human.subheadObject,
      ...systemWeights.bold,
      fontFamily,
    },
    callout: {
      ...human.calloutObject,
      ...systemWeights.semibold,
      fontFamily,
    },
    subheadEmphasized: {
      ...human.subheadObject,
      ...systemWeights.semibold,
      fontFamily,
    },
    body: {
      ...human.bodyObject,
      ...systemWeights.regular,
      fontFamily,
    },
    body1: {
      ...material.body1Object,
      ...systemWeights.regular,
      fontFamily,
    },
    caption1: {
      ...human.caption1Object,
      ...systemWeights.regular,
      fontFamily,
    },
    overline: {
      fontSize: '12px',
      letterSpacing: 1.5,
      lineHeight: '20px',
      fontFamily,
    },
  },
}

export type AppTheme = typeof DefaultTheme

export const DishHomeTheme: AppTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#0015EA',
  },
}

export const DeviceSupportTheme: AppTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#E60000',
    disabledSubmitButton: '#CACACA',
  },
}

interface ThemedProps {
  theme: AppTheme
}

type ThemeGetter<T> = (props: ThemedProps) => T

type ThemeGetters = {
  [T in keyof AppTheme]: {
    // Should be ThemeGetter<Theme[T][U]> but styled-components doesn't like
    // the font styles then because of `fontVariant`
    [U in keyof AppTheme[T]]: ThemeGetter<any>
  }
}

// Theme getter functions for convenience in styled-components
export const theme = Object.keys(DefaultTheme).reduce(
  (acc, key) => ({
    ...acc,
    [key]: Object.keys((DefaultTheme as any)[key]).reduce(
      (acc, innerKey) => ({
        ...acc,
        [innerKey]: ({ theme }: ThemedProps) => {
          const category = (theme as any)[key] || (DefaultTheme as any)[key]
          return category[innerKey]
        },
      }),
      {}
    ),
  }),
  {}
) as ThemeGetters

export const Theme = React.createContext<AppTheme>(DefaultTheme)

export const useTheme = () => useContext(Theme)

interface Props {
  theme?: AppTheme
  children: any
}

export const AppThemeProvider = ({ theme = DefaultTheme, children }: Props) => (
  <ThemeProvider theme={theme}>
    <Theme.Provider value={theme}>{children}</Theme.Provider>
  </ThemeProvider>
)
