import React, { CSSProperties, useRef } from 'react'
import styled from 'styled-components'
import { Title2, Body } from 'src/common/text-styles'
import * as Inputs from 'src/common/components/TextInputs'
import { InputList, OnContinue, ExtraButton, PreFillEffect } from '../types'
import { theme } from 'src/common/theme'
import {
  Form,
  wrapInput,
  SubmitButton,
  ExtraButton as FormExtraButton,
  PreFill,
} from 'src/common/components/Form'
import { FormFieldSet, SetFieldFn } from 'src/common/components/Form/types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const Prompt = styled(Body)`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
`

const SubmitButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;
  margin-left: 24px;
  margin-right: 24px;
`
const ExtraButtonContainer = styled(SubmitButtonContainer)`
  display: flex;
  margin-top: 16px;
`

const StyledExtraButton = styled(FormExtraButton)`
  display: flex;
  width: 260px;
  align-self: center;
  height: 150px;
`

const StyledSubmitButton = styled(SubmitButton)`
  display: flex;
  width: 260px;
  align-self: center;
`

const ErrorText = styled(Body)`
  display: flex;
  text-align: center;
  align-self: center;
  color: ${theme.colors.destructiveAction};
`

interface MetaProps {
  topImageSVGElement?: JSX.Element
  disclaimer?: JSX.Element
  title: string
  prompt: string
  inputs: InputList
  primaryButtonTitle?: string
  secondaryButton?: ExtraButton
  extraButtons?: ExtraButton[]
  preFillEffect?: PreFillEffect
  consentText?: JSX.Element
}

interface Props {
  style?: CSSProperties
  onContinue: OnContinue
  onSecondary?: OnContinue
  working: boolean
  secondaryWorking: boolean
  errorMessage?: string
}

type State = {
  inputElements: React.ReactNodeArray
  extraButtonElements: React.ReactNodeArray
}

const Content = ({
  topImageSVGElement,
  title,
  prompt,
  inputs,
  primaryButtonTitle = 'Continue',
  secondaryButton,
  extraButtons = [],
  preFillEffect = () => {},
  disclaimer,
  consentText,
}: MetaProps) =>
  class FormContent extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props)

      const inputElements = inputs.map((input, index) => {
        const type = typeof input === 'string' ? input : input.type
        const label = typeof input === 'string' ? undefined : input.label
        const hint = typeof input === 'string' ? undefined : input.hint
        const hintComponent =
          typeof input === 'string' ? undefined : input.hintComponent
        const validation =
          typeof input === 'string' ? undefined : input.validation

        const key = index.toString()

        const Input = wrapInput(Inputs[type])
        return (
          <Input
            key={key}
            name={key}
            label={label}
            hint={hint}
            hintComponent={hintComponent}
            validation={validation}
          />
        )
      })

      const extraButtonElements = extraButtons.map((info, index) => (
        <ExtraButtonContainer key={String(index)}>
          <StyledExtraButton
            secondary={true}
            title={info.title}
            onPress={(fields: FormFieldSet) => this.onExtraPress(info, fields)}
          />
        </ExtraButtonContainer>
      ))

      this.state = {
        inputElements,
        extraButtonElements,
      }
    }

    onPreFill = (setField: SetFieldFn) => {
      const valueSetters = inputs.map((_, index) => (value: string) => {
        setField(index.toString(), { value })
      })
      return preFillEffect(...valueSetters)
    }

    onContinue = (fields: FormFieldSet) => {
      const { onContinue } = this.props
      const values = this.mapFieldSetToValueArray(fields)
      onContinue(...values)
    }

    onSecondary = (fields: FormFieldSet) => {
      const { onSecondary } = this.props
      const values = this.mapFieldSetToValueArray(fields)
      if (onSecondary) {
        onSecondary(...values)
      }
    }

    onExtraPress = (info: ExtraButton, fields: FormFieldSet) => {
      const values = this.mapFieldSetToValueArray(fields)
      info.onPress(...values)
    }

    mapFieldSetToValueArray = (fields: FormFieldSet) => {
      const result = []
      for (let i = 0; i < inputs.length; i++) {
        const field = fields[i.toString()]
        result.push(field?.value ?? '')
      }
      return result
    }

    render() {
      const { style, working, errorMessage, secondaryWorking } = this.props
      const { inputElements, extraButtonElements } = this.state
      const secondayButtonElement = (
        info: ExtraButton,
        secondaryWorking: boolean
      ) => (
        <ExtraButtonContainer>
          <StyledExtraButton
            key={info.title}
            secondary={true}
            title={info.title}
            onPress={this.onSecondary}
            disabled={secondaryWorking}
          />
        </ExtraButtonContainer>
      )

      const ReturnContainer = () => {
        const onPressRef = useRef<() => void>(() => {})

        return (
          <Container
            style={style}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                onPressRef.current()
              }
            }}
          >
            <Form>
              <PreFill onPreFill={this.onPreFill} />
              {topImageSVGElement ? topImageSVGElement : undefined}
              <Title2>{title}</Title2>
              <Prompt>{prompt}</Prompt>
              {disclaimer}
              {inputElements}
              {Boolean(consentText) && consentText}
              <ErrorText>{errorMessage}</ErrorText>
              <SubmitButtonContainer>
                <StyledSubmitButton
                  testID="continue-btn"
                  onPress={this.onContinue}
                  title={primaryButtonTitle}
                  disabled={working}
                  onPressRef={onPressRef}
                />
              </SubmitButtonContainer>
              {secondaryButton &&
                secondayButtonElement(secondaryButton, secondaryWorking)}
              {extraButtonElements}
            </Form>
          </Container>
        )
      }
      return <ReturnContainer />
    }
  }

export default Content
