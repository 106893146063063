import { START_PASSWORD_RESET } from 'src/apollo/mutations'
import client from 'src/apollo'

export const sendPasswordResetCode = async (
  versionCode: string,
  email: string,
  appBrandingKey: string
): Promise<boolean> => {
  const { data } = await client(versionCode).mutate({
    mutation: START_PASSWORD_RESET,
    variables: {
      email,
      appBrandingKey,
    },
    context: {
      realm: 'none',
    },
  })
  if (!data.startPasswordReset.success) {
    throw Error('Error starting reset')
  }
  return data.startPasswordReset.success
}
