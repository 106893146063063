import { makeInput } from './GenericInput'
import { localize } from 'src/lib/localize'

// According to SNCR, they validate an email using Java's
// `javax.mail.internet.InternetAddress` class, which in turn is based off
// RFC 822
const RFC_822_REGEX =
  // eslint-disable-next-line no-control-regex
  /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/

export const defaultValidation = (email: string) =>
  rfc822Validation(email) && oktaValidation(email)

export const rfc822Validation = (email: string) => RFC_822_REGEX.test(email)

// Additional Okta rules
// From: https://help.okta.com/en/prod/Content/Topics/Directory/Reference_Directories.htm
const OKTA_ALLOWED_CHARS = /^[a-zA-Z0-9!@#$%&'*+\-/=?^_`.{|}~]+$/
export const oktaValidation = (email: string) => {
  if (!OKTA_ALLOWED_CHARS.test(email)) {
    return false
  }

  const NUM_ATS = email.match(/@/g)?.length || 0
  if (NUM_ATS !== 1) {
    return false
  }

  const [local, domain] = email.split('@')
  if (
    local.startsWith('.') ||
    local.endsWith('.') ||
    local.indexOf('..') !== -1
  ) {
    return false
  }

  const domainParts = domain.split('.')
  if (domainParts.length <= 1) {
    return false
  }

  const tld = domainParts[domainParts.length - 1]
  if (tld.length < 2 || tld.length > 20) {
    return false
  }

  return true
}

export default makeInput({
  textInputProps: {
    autoCapitalize: 'none',
    keyboardType: 'email-address',
    placeholder: 'john.smith@example.com',
  },
  defaultLabel: localize('LABEL')('Email'),
  defaultValidate: defaultValidation,
  defaultErrorMessage: localize('LABEL')('Not a valid email address.'),
})
