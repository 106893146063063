/*
Okta defines "username" as the before @ part. So in your case it's "rick.smith". More than 4 consecutive characters will be flagged by the policy.

The logic this setting uses is as follows:

The policy analyzes the username as "parts" that are separated by punctuation

Any part that contains less than 4 characters is not considered by the policy

The password cannot contain any of these individual parts in their entirety, but can contain a set of characters that comprise a portion of an individual part.
*/

export const noEmailParts = (email: string, password: string) => {
  // Okta defines "username" as the before @ part.
  // The policy analyzes the username as "parts" that are separated by punctuation

  // NOTE: In their examples they also appear to consider the domain as parts
  // so I'm going with the more strict rule.
  // Also I'm not even gonna bother trying to guess how they handle an email
  // like "Ed Jones"@business.com
  const parts = email.split(/[.@]/)

  // Any part that contains less than 4 characters is not considered by the policy
  const significantParts = parts.filter(p => p.length >= 4)

  // The password cannot contain any of these individual parts in their entirety,
  const invalidPart = significantParts.find(p => password.includes(p))

  return invalidPart === undefined
}
