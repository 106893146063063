import { makeInput } from './GenericInput'

const cleanedLineNumber = (lineNumber: string): string => {
  const numberDigits = lineNumber.match(/\d+/g)

  if (numberDigits == null) {
    return ''
  } else {
    return numberDigits.join('')
  }
}

const defaultValidation = (phoneNumber: string): boolean => {
  const cleanNumber = cleanedLineNumber(phoneNumber)
  return cleanNumber.length === 10 || cleanNumber.length === 11
}

export default makeInput({
  textInputProps: {
    keyboardType: 'phone-pad',
    placeholder: '(123) 555 - 1234',
  },
  defaultLabel: 'Phone Number',
  defaultValidate: defaultValidation,
  defaultErrorMessage: 'Not a valid phone number.',
})
