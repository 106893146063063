import React, { useContext, CSSProperties } from 'react'
import styled from 'styled-components'
import { Theme, theme } from 'src/common/theme'

const ThemedButton = styled.button`
  box-sizing: border-box;
  justify-content: center;
  width: 100%;
  border-radius: 50px;
  border: 2px solid;
  text-align: center;
  height: 48px;
  ${theme.textStyles.subhead};
  outline: none;
`
const ThemedButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-vertical: 12px;
  align-items: flex-start;
  border-radius: 50px;
  height: 48px;
  position: relative;
  overflow: hidden;
`

const HoverView = styled.div`
  display: flex;
  position: absolute;
  justify-content: flex-start;
  height: 48px;
  left: 0px;
  right: 0px;
  background-color: transparent;
  :hover {
    cursor: pointer;
    opacity: 0.5;
    background-color: white;
  }
`

export interface Props {
  style?: CSSProperties
  title: string
  onPress: () => void
  disabled?: boolean
  submitType?: boolean
  secondary?: boolean
  contextualBackgroundColor?: string
  contextualFontColor?: string
  overrideActiveFontColor?: string
  overrideBorderColor?: string
  testID?: string
}

const InternalButton = ({
  style,
  title,
  onPress,
  disabled,
  submitType,
  secondary,
  contextualBackgroundColor,
  contextualFontColor,
  overrideActiveFontColor,
  overrideBorderColor,
}: Props) => {
  const _isPrimary = !secondary
  const _isSecondary = secondary
  const _isEnabled = !disabled
  const _isPrimaryDisabled = !secondary && disabled
  const _isSecondaryDisabled = secondary && disabled
  const _isSubmitTypeDisabled = submitType && disabled

  const backgroundColor = () => {
    if (_isSubmitTypeDisabled) {
      return theme.colors.disabledSubmitButton
    } else if (_isPrimaryDisabled) {
      return theme.colors.disabledGrey
    } else if (_isPrimary) {
      return contextualBackgroundColor
    } else {
      return 'transparent'
    }
  }

  const border = () => {
    if (overrideBorderColor) {
      return overrideBorderColor
    }
    if (_isSubmitTypeDisabled) {
      return theme.colors.disabledSubmitButton
    } else if (_isEnabled) {
      return contextualBackgroundColor
    } else {
      return theme.colors.disabledGrey
    }
  }

  const textColor = () => {
    if (_isPrimaryDisabled) {
      return '#9b9b9b'
    } else if (_isPrimary) {
      return overrideActiveFontColor || contextualFontColor
    } else if (_isSecondaryDisabled) {
      return theme.colors.disabledGrey
    } else if (_isSecondary) {
      return overrideActiveFontColor || contextualBackgroundColor
    } else {
      return overrideActiveFontColor || contextualFontColor
    }
  }
  const theme = useContext(Theme)

  const overrideStyles: CSSProperties = {
    backgroundColor: backgroundColor(),
    borderColor: border(),
    color: textColor(),
    ...style,
  }

  return (
    <ThemedButtonContainer>
      <ThemedButton
        onClick={onPress}
        style={overrideStyles}
        disabled={disabled}
      >
        {title}
      </ThemedButton>
      <HoverView onClick={onPress}></HoverView>
    </ThemedButtonContainer>
  )
}

export const LightButton = (props: Props) => {
  const theme = useContext(Theme)
  if (theme.colors.barStyle === 'dark-content') {
    return <DarkButton {...props} />
  }
  return (
    <InternalButton
      {...props}
      contextualBackgroundColor={theme.colors.white}
      contextualFontColor={theme.colors.primary}
      overrideActiveFontColor={props.overrideActiveFontColor}
    />
  )
}

export const DarkButton = (props: Props) => {
  const theme = useContext(Theme)

  return (
    <InternalButton
      {...props}
      contextualBackgroundColor={theme.colors.primary}
      contextualFontColor={theme.colors.white}
      overrideActiveFontColor={props.overrideActiveFontColor}
    />
  )
}
