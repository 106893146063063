import CombinedStyleSheet from "../internal/CombinedStyleSheet";
import suffixProperties from "../internal/suffixProperties";
import material from "./material";
import systemDenseWeights from "../helpers/systemDenseWeights";
import sanFranciscoSpacing from "../helpers/sanFranciscoSpacing";

// https://material.io/guidelines/style/typography.html#typography-styles

const getStylesForColor = color => {
  const colorSuffix = color === "white" ? "White" : "";
  return {
    display4: {
      ...material[`display4${colorSuffix}Object`],
      ...systemDenseWeights.light,
      lineHeight: '164px'
    },
    display3: {
      ...material[`display3${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      lineHeight: '82px'
    },
    display2: {
      ...material[`display2${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      lineHeight: '66px'
    },
    display1: {
      ...material[`display1${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      lineHeight: '50px'
    },
    headline: {
      ...material[`headline${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      lineHeight: '40px'
    },
    title: {
      ...material[`title${colorSuffix}Object`],
      ...systemDenseWeights.semibold,
      fontSize: 21,
      lineHeight: '36px',
      letterSpacing: sanFranciscoSpacing(21)
    },
    subheading: {
      ...material[`subheading${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      fontSize: 17,
      lineHeight: '30px',
      letterSpacing: sanFranciscoSpacing(17)
    },
    body2: {
      ...material[`body2${colorSuffix}Object`],
      ...systemDenseWeights.semibold,
      fontSize: 15,
      lineHeight: '30px',
      letterSpacing: sanFranciscoSpacing(15)
    },
    body1: {
      ...material[`body1${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: sanFranciscoSpacing(15)
    },
    caption: {
      ...material[`caption${colorSuffix}Object`],
      ...systemDenseWeights.regular,
      fontSize: 13,
      lineHeight: '20px',
      letterSpacing: sanFranciscoSpacing(13)
    },
    button: {
      ...material[`button${colorSuffix}Object`],
      ...systemDenseWeights.semibold,
      fontSize: 15,
      lineHeight: '26px',
      letterSpacing: sanFranciscoSpacing(15)
    }
  };
};

export default CombinedStyleSheet.create({
  ...getStylesForColor("black"),
  ...suffixProperties(getStylesForColor("white"), "White")
});