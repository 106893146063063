import client from 'src/apollo'
import { RESET_PASSWORD } from 'src/apollo/mutations'

export const resetPassword = async (
  versionCode: string,
  email: string,
  code: string,
  newPassword: string
): Promise<boolean> => {
  const { data } = await client(versionCode).mutate({
    mutation: RESET_PASSWORD,
    variables: {
      email,
      code,
      newPassword,
    },
    context: {
      realm: 'none',
    },
  })
  return data.resetPassword.success
}
