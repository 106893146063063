import CombinedStyleSheet from "../internal/CombinedStyleSheet";
import suffixProperties from "../internal/suffixProperties";
import systemWeights from "../helpers/systemWeights";
import sanFranciscoSpacing from "../helpers/sanFranciscoSpacing";
import iOSColors from "../helpers/iOSColors";

// https://developer.apple.com/ios/human-interface-guidelines/visual-design/typography/

const colors = {
  black: iOSColors.black,
  white: iOSColors.white
};

const getStylesForColor = color => ({
  largeTitle: {
    fontSize: 34,
    lineHeight: '41px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(34),
    color: colors[color]
  },
  title1: {
    fontSize: 28,
    lineHeight: '34px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(28),
    color: colors[color]
  },
  title2: {
    fontSize: 22,
    lineHeight: '28px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(22),
    color: colors[color]
  },
  title3: {
    fontSize: 20,
    lineHeight: '25px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(20),
    color: colors[color]
  },
  headline: {
    fontSize: 17,
    lineHeight: '22px',
    ...systemWeights.semibold,
    letterSpacing: sanFranciscoSpacing(17),
    color: colors[color]
  },
  body: {
    fontSize: 17,
    lineHeight: '22px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(17),
    color: colors[color]
  },
  callout: {
    fontSize: 16,
    lineHeight: '21px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(16),
    color: colors[color]
  },
  subhead: {
    fontSize: 15,
    lineHeight: '20px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(15),
    color: colors[color]
  },
  footnote: {
    fontSize: 13,
    lineHeight: '18px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(13),
    color: colors[color]
  },
  caption1: {
    fontSize: 12,
    lineHeight: '16px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(12),
    color: colors[color]
  },
  caption2: {
    fontSize: 11,
    lineHeight: '13px',
    ...systemWeights.regular,
    letterSpacing: sanFranciscoSpacing(11),
    color: colors[color]
  }
});

export default CombinedStyleSheet.create({
  ...getStylesForColor("black"),
  ...suffixProperties(getStylesForColor("white"), "White")
});