import { makeInput } from './GenericInput'
import PasswordHintText, { allValid } from './PasswordHintText'
import { localize } from 'src/lib/localize'

export default makeInput({
  textInputProps: {
    autoCapitalize: 'none',
    type: 'password',
    hasVisibilityToggle: true,
    placeholder: '••••••••',
  },
  defaultLabel: localize('LABEL')('Password'),
  defaultValidate: allValid,
  defaultErrorMessage: localize('LABEL')('Invalid password'),
  defaultHintComponent: PasswordHintText,
})
