import React from 'react'
import styled from 'styled-components'
import { useTheme, theme } from 'src/common/theme'
import { CommonLoading as Loading } from 'react-loadingg'

const SpinnerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
  margin-top: -20px;
  background-color: ${theme.colors.disabledGrey}60;
`

interface Props {
  visible: boolean
}

const LoadingOverlay = ({ visible }: Props) => {
  const { primary } = useTheme().colors
  return visible ? (
    <SpinnerBox style={{ color: primary }}>
      <Loading color={primary} />
    </SpinnerBox>
  ) : null
}

export default LoadingOverlay
