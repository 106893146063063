import { localize } from 'src/lib/localize'
import { sendPasswordResetCode } from 'src/Reset/businessLogic/sendPasswordResetCode'

interface Props {
  email?: string
  versionCode: string
  brandingKey: string
  onSuccess: () => void
}
export const resendCode = async ({
  email,
  versionCode,
  brandingKey,
  onSuccess,
}: Props) => {
  if (email === undefined) {
    return localize('LABEL')('Failed to send new code, please try again')
  }

  const success = await sendPasswordResetCode(versionCode, email, brandingKey)
  if (success) {
    onSuccess()
  } else {
    return localize('LABEL')('Failed to send new code, please try again')
  }
}
