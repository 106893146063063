import { useEffect } from 'react'
import { Props } from './types'

const HiddenInput = ({ onChangeValidationState }: Props) => {
  useEffect(() => {
    if (onChangeValidationState) {
      onChangeValidationState(true)
    }
  }, [onChangeValidationState])

  return null
}

export default HiddenInput
