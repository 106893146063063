import client from 'src/apollo'
import { CHECK_CODE } from 'src/apollo/mutations'
import { localize } from 'src/lib/localize'
import { CheckCodeResult } from 'src/Reset/types'

/**
 * Checks the verification code used when resetting a password.
 * @param versionCode The whitelabel version code
 * @param email The email address the verification code was sent to
 * @param code The verification code
 * @returns A {@link CheckCodeResult}
 */
export const checkCode = async (
  versionCode: string,
  email: string,
  code: string
): Promise<CheckCodeResult> => {
  try {
    const { data } = await client(versionCode).mutate({
      mutation: CHECK_CODE,
      variables: {
        email,
        code,
      },
      context: {
        realm: 'none',
      },
    })
    const success = data.checkPasswordResetCode.success
    const errorMessage = success
      ? undefined
      : localize('LABEL')('Invalid code, please try again')

    return {
      success,
      errorMessage,
    }
  } catch (err) {
    const defaultError = new Error(localize('LABEL')('Error verifying code'))

    if (err instanceof Error) {
      switch (err.message) {
        case 'GraphQL error: Password reset code has expired':
          return {
            success: false,
            errorMessage: localize('LABEL')('Code has expired'),
          }
        default:
          throw defaultError
      }
    } else {
      throw defaultError
    }
  }
}
