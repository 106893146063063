import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme } from 'src/common/theme'
import { isMobileOnly } from 'react-device-detect'
import { useWhiteLabel } from 'src/white-label'

const Root = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: felx-start;
  background-color: ${theme.colors.lightBackground};
`

const BrowserContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${theme.colors.white};
  max-width: 420px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  box-sizing: border-box;
  border: 1px solid ${theme.colors.separator};
  border-radius: 10px;
  margin-top: 20px;
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: ${theme.colors.lightBackground};
  max-width: 420px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
  min-height: 700px;
`

const LogoContainer = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

interface ContainerProps {
  title: string
  children?: JSX.Element
}

const Container = ({ title, children }: ContainerProps) => {
  useEffect(() => {
    document.title = title
  }, [title])
  const { logo: Logo } = useWhiteLabel()
  return (
    <Root>
      {isMobileOnly ? (
        <MobileContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          {children}
        </MobileContainer>
      ) : (
        <BrowserContainer>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          {children}
        </BrowserContainer>
      )}
    </Root>
  )
}

export default Container
